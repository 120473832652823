import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import {
  Medailon,
  ReferenceBanner,
  ReferenceContainer,
} from "@igloonet-web/shared-ui"

import Logo from "../../../images/reference/fektvut/logofekt.png"
import Avatar from "../../../images/reference/fektvut/avatar.jpg"
import Top from "../../../images/reference/fektvut/top.jpg"

class FEKT extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const pozadi = {
      backgroundImage: `url(${Top})`,
    }

    return (
      <Layout>
        <Helmet>
          <title>Reference na marketingové práce pro FEKT VUT | igloonet</title>
          <meta
            name="description"
            content="Jak se nám povedlo zastavit propad podaných přihlášek u Fakulty elektrotechniky? S FEKTem dlouhodobě spolupracujeme na marketingu sociálních sítí. Cílem je oslovovat potenciální studenty v průběhu celého roku."
          />
        </Helmet>
        <ReferenceBanner
          styles={pozadi}
          logo={Logo}
          alt="logo"
          extodkaz="//fekt.vut.cz//"
          heading="Zastavit propad počtu podaných přihlášek"
          odstavec="2016 - 2021"
        />

        <ReferenceContainer>
          <Medailon
            wide
            image={Avatar}
            alt=""
            text="Spolupráce se společností Igloonet je z našeho pohledu velmi přínosná.
            Musíme vyzdvihnout profesionalitu pracovníků firmy, jejich kreativitu a nápady,
            které pomáhají fakultě řešit problém nedostatku dobrých studentů na technické
            univerzitě. Díky spolupráci se fakultě daří uchazeče o studium oslovovat atraktivním
            způsobem a se zapálením pro elektrotechniku."
            name="doc. Ing. Jiří Háze, Ph.D."
            position="Proděkan pro vnější vztahy a zahraniční styky, Vedoucí ústavu"
          />
        </ReferenceContainer>

        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default FEKT
